import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';

const TextField = ({ name, variant, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <MuiTextField
      {...field}
      name={name}
      variant={variant}
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ' '}
      {...props}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

TextField.defaultProps = {
  variant: 'filled',
};

export default TextField;
